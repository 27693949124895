.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 18px !important;
  background-color: #f0f0f0 !important;
  color: #000 !important;
  border: 2px solid #796666 !important;
  border-radius: 0.3rem !important;
  display: inline-block;
  position: relative;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0 !important;
  border-bottom: 1px solid #aeaeae !important;
  border-top-left-radius: 0.3rem !important;
  border-top-right-radius: 0.3rem !important;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000 !important;
  font-weight: bold;
  font-size: 1.2rem * 1.18 !important;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem !important;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent !important;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 2rem !important;
  text-align: center;
}
.react-datepicker__month-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae !important;
  width: 70px;
}

.react-datepicker__time {
  position: relative;
  background: white;
}
.react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}
ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}
li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
}
.react-datepicker__week-number {
  color: #ccc !important;
  display: inline-block;
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  text-align: center;
  margin: 0.166rem !important;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000 !important;
  display: inline-block;
  width: 1.7rem !important;
  line-height: 1.7rem !important;
  text-align: center;
  margin: 0.166rem !important;
}
.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0 !important;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem !important;
  border: 1px solid #aeaeae !important;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}

.react-datepicker__today-button {
  background: #f0f0f0 !important;
  border-top: 1px solid #aeaeae !important;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
.react-datepicker-time__header {
  font-size: 0.8rem * 1.8;
  height: 3.2rem;
}
.react-datepicker__current-month {
  font-size: 0.8rem * 1.8;
}
.react-datepicker__navigation {
  border: 1.8 * 0.45rem solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #1e88e5 !important;
  /*left: -70px !important; */
}
.react-datepicker__navigation--next {
  border-left-color: #1e88e5 !important;
  right: 0px !important;
}
.react-datepicker__day-names,
.react-datepicker__week {
  padding: 10px !important;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  line-height: 3rem !important;
  margin-right: 17px !important;
  width: 23px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 85px !important;
}
.react-datepicker__time-container {
  width: 90px !important;
}
.react-datepicker__header--time {
  padding-bottom: 46px !important;
}
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 23px !important;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 20px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  color: #fff !important;
  background-color: #1e88e5 !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  color: #fff !important;
  background-color: #1e88e5 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #1e88e5 !important;
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 18px !important;
}
