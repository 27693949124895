.table-actions {
  width: 150px;
}
.skin-blue .main-header .navbar {
  background-color: #1e88e5 !important;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #1e88e5 !important;
}
.skin-blue .main-header .logo {
  background-color: #1c81d9 !important;
}
.btn-primary {
  background-color: #1e88e5 !important;
  border-color: #1e88e5;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.hover {
  background-color: #1c81d9 !important;
  border: 1px solid #1c81d9 !important;
}
.box.box-primary {
  border-top-color: #1c81d9 !important;
}
.fixed .main-header {
  -webkit-box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5) !important;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5) !important;
}
a {
  color: #1e88e5;
}
.perletivo {
  margin: 0px 10px;
}
.perletivo label {
  font-weight: 400 !important;
  color: #fff !important;
}
.btn-primary:active {
  border-color: #1e88e5 !important;
}
.btn-primary:focus {
  border-color: #1e88e5 !important;
}
.user-panel > .image > img {
  max-width: 40px !important;
  height: 40px !important;
}
.btn-primary {
  margin-right: 5px !important;
}
.react-bs-table-container {
  padding: 0px;
}
.react-bs-table-container table th {
  width: 150px;
}
.react-bs-table-container table td {
  width: 238px;
}
.fc-color-picker > li {
  font-size: 16px !important;
  margin-right: 30px !important;
}

.icones i {
  padding: 3px;
}
.fa-book {
  padding-right: 5px !important;
}

.btn-infor {
  background-color: #1e88e5 !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}
.btn-download {
  background-color: #1e88e5 !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}
.btn-pencil {
  background-color: #00a65a !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}
.btn-list {
  background-color: #dd4b39 !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}
.btn-add {
  background-color: #e62309 !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}
.btn-trash {
  background-color: #f39c12 !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}
.btn-clone {
  background-color: #605ca8 !important;
  padding: 5px;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 2px;
}

.Dropzone span {
  padding: 15px;
}
body {
  height: 0 !important;
}
.nav-tabs-custom {
  background: transparent !important;
}
.white {
  position: relative;
  border-radius: 3px;
  background: #ffffff !important;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-top: 3px solid #1c81d9;
}

.nav > li > a > i {
  padding-right: 5px !important;
}

.react-datepicker__input-container {
  display: block !important;
}
