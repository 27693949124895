.infor {
  background-color: #1e88e5 !important;
  border-color: #1e88e5;
  color: #ffffff !important;
  height: 100px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.warning {
  background-color: #f5b549 !important;
  border-color: #f5b549;
  color: #ffffff !important;
  height: 100px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}
.error {
  background-color: #dd4b39 !important;
  border-color: #d73925;
  color: #ffffff !important;
  height: 100px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.success {
  background-color: #00b362 !important;
  border-color: #00b362;
  color: #ffffff !important;
  height: 100px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.question {
  height: 100px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.swal2-success-circular-line-right {
  color: #ffffff !important;
}

.swal2-header h2 {
  color: #ffffff !important;
}
